import React from 'react';
import {
  ChakraProvider,
  extendTheme
} from '@chakra-ui/react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import { Index } from 'views/index';

const theme = extendTheme({
  colors: {
    base: {
      "50": "#FCE8E9",
      "100": "#F8BFC2",
      "200": "#F3969A",
      "300": "#EE6D73",
      "400": "#E9444C",
      "500": "#E41B24",
      "600": "#B7151D",
      "700": "#891016",
      "800": "#5B0B0F",
      "900": "#2E0507"
    },
    sub : {
      "50": "#F2F2F2",
      "100": "#DBDBDB",
      "200": "#C4C4C4",
      "300": "#ADADAD",
      "400": "#969696",
      "500": "#808080",
      "600": "#666666",
      "700": "#4D4D4D",
      "800": "#333333",
      "900": "#1A1A1A"
    },
    // master: '#01007C',
    slave: '#3E4FA0',
    // sub:  '#F1F1F5',
    opposite: 'white',
    master: {
      "50": "#FCE8E9",
      "100": "#F8BFC2",
      "200": "#F3969A",
      "300": "#EE6D73",
      "400": "#E9444C",
      "500": "#E41B24",
      "600": "#B7151D",
      "700": "#891016",
      "800": "#5B0B0F",
      "900": "#2E0507"
    }
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Index />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
