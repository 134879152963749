import * as React from 'react';
import {
  Container,
  Spacer,
  HStack,
  VStack,
  Image,
  Text,
  Link,
  Center,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  SimpleGrid,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  IconButton,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {
  FiMenu,
} from 'react-icons/fi';

export const Index = () => {
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const images = [
    '/static/images/slide/1.jpg',
    '/static/images/slide/2.jpg',
    '/static/images/slide/3.jpg',
    '/static/images/slide/4.jpg',
    '/static/images/slide/5.jpg',
    '/static/images/slide/6.jpg',
    '/static/images/slide/7.jpg',
    '/static/images/slide/8.jpg',
    '/static/images/slide/9.jpg',
    '/static/images/slide/10.jpg',
    '/static/images/slide/11.jpg',
  ];

  return (
    <Container
      maxW='container-lg'
      h='full'
    >
      {/* Header */}
      <HStack
        w='full'
        h={20}
        bg='#604461'
        px={5}
      >
        <Image
          h={12}
          src='/static/images/logo.png' alt='楽楽'
        />
        <Spacer />

        <IconButton
          aria-label='menu'
          icon={<FiMenu />}
          onClick={() => setOpenMenu(true)}
          colorScheme='teal'
        />
        <Drawer
          isOpen={openMenu}
          placement='right'
          onClose={() => setOpenMenu(false)}
          returnFocusOnClose={false}
        >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>メニュー</DrawerHeader>

          <DrawerBody>
            <UnorderedList>
              <ListItem>
                <Link href='#top' color='#604461' onClick={() => setOpenMenu(false)}>トップ</Link>
              </ListItem>
              <ListItem>
                <Link href='#photo' color='#604461' onClick={() => setOpenMenu(false)}>店内写真</Link>
              </ListItem>
              <ListItem>
                <Link href='#info' color='#604461' onClick={() => setOpenMenu(false)}>店舗情報</Link>
              </ListItem>
              <ListItem>
                <Link href='#menu' color='#604461' onClick={() => setOpenMenu(false)}>料金・メニュー</Link>
              </ListItem>
              <ListItem>
                <Link href='#contact' color='#604461' onClick={() => setOpenMenu(false)}>お問合せ</Link>
              </ListItem>
            </UnorderedList>
          </DrawerBody>
        </DrawerContent>
      </Drawer>


      </HStack>
      {/* Header */}

      {/* Main */}
      <VStack
        mt={5}
        w='full'
        h='full'
        spacing={5}
      >

        <Image
          w='full'
          src='/static/images/header.jpg' alt='楽楽'
        />

        <Card
          id='photo'
          w='full'
          bg='#efd4cd'
        >
          <CardHeader>
            <Heading
              size='md'
              color='#604461'
            >
              店内写真
            </Heading>
          </CardHeader>
          <CardBody
          >
            <Slide>
              {images.map((image, index) => (
                <div className='each-slide-effect' key={index}>
                  <Image src={image} alt='店内写真' />
                </div>
              ))}
            </Slide>
          </CardBody>
        </Card>

        <Card
          id='info'
          w='full'
          bg='#efd4cd'
        >
          <CardHeader>
            <Heading
              size='md'
              color='#604461'
            >
              店舗情報
            </Heading>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td
                      w='25%'
                    >
                      <Text
                        fontSize='lg'
                        as='b'
                      >
                        所在地
                      </Text>
                    </Td>
                    <Td>
                      <Link href='#access' color='teal.500'>
                        川口市西川口 1-3-10 西川口230ビル 2階
                      </Link>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text
                        fontSize='lg'
                        as='b'
                      >
                        営業時間
                      </Text>
                    </Td>
                    <Td>10:00 〜 ラスト</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text
                        fontSize='lg'
                        as='b'
                      >
                        電話予約
                      </Text>
                    </Td>
                    <Td>
                      <Link href='#contact' color='teal.500'>
                      080-3472-6293
                      </Link>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text
                        fontSize='lg'
                        as='b'
                      >
                        クレジットカード
                      </Text>
                    </Td>
                    <Td>
                      <HStack
                        spacing={5}
                      >
                        <Image
                          src='/static/images/visa.png' alt='visa'
                          w={24}
                        />
                        <Image
                          src='/static/images/paypay.png' alt='paypay'
                          w={20}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>

        <Card
          id='menu'
          w='full'
          bg='#efd4cd'
        >
          <CardHeader>
            <Heading
              size='md'
              color='#604461'
            >
              料金・メニュー
            </Heading>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td
                      w='25%'
                    >
                      <Text
                        fontSize='2xl'
                        as='b'
                      >
                        マサージコース
                      </Text>
                    </Td>
                    <Td>
                      <SimpleGrid
                        columns={2}
                        spacing={10}
                      >
                        <Center>40分</Center>
                        <Center>3,000円</Center>
                        <Center>60分</Center>
                        <Center>3,980円</Center>
                        <Center>90分</Center>
                        <Center>6,500円</Center>
                        <Center>120分</Center>
                        <Center>8,500円</Center>
                      </SimpleGrid>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                    >
                      <Text
                        fontSize='2xl'
                        as='b'
                      >
                        オイルコース
                      </Text>
                    </Td>
                    <Td>
                      <SimpleGrid
                        columns={2}
                        spacing={10}
                      >
                        <Center>30分</Center>
                        <Center>2,980円</Center>
                        <Center>60分</Center>
                        <Center>6,980円</Center>
                        <Center>90分</Center>
                        <Center>10,500円</Center>
                        <Center>120分</Center>
                        <Center>12,500円</Center>
                      </SimpleGrid>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                    >
                      <Text
                        fontSize='2xl'
                        as='b'
                      >
                        アカスリコース
                      </Text>
                    </Td>
                    <Td>
                      <SimpleGrid
                        columns={2}
                        spacing={10}
                      >
                        <Center>30分</Center>
                        <Center>3,980円</Center>
                        <Center>60分</Center>
                        <Center>7,980円</Center>
                        <Center>90分</Center>
                        <Center>11,500円</Center>
                        <Center>120分</Center>
                        <Center>13,980円</Center>
                      </SimpleGrid>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </CardBody>
          <CardFooter>
            <Text fontSize='sm'>※全て税込価格です。</Text>
          </CardFooter>
        </Card>

        <Card
          id='access'
          w='full'
          bg='#efd4cd'
        >
          <CardHeader>
            <Heading
              size='md'
              color='#604461'
            >
              アクセスMAP
            </Heading>
          </CardHeader>
          <CardBody>
            <iframe
              title='map'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d202.21222753599355!2d139.70431811844853!3d35.81476748820144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601894a4582839c1%3A0x14b0561457b59611!2z44CSMzMyLTAwMjEg5Z-8546J55yM5bed5Y-j5biC6KW_5bed5Y-j77yR5LiB55uu77yT4oiS77yR77yQ!5e0!3m2!1sja!2sjp!4v1704588366933!5m2!1sja!2sjp'
              width='100%'
              height={450}
              frameBorder={0}
              style={{ border:0 }}
              aria-hidden='false'
            />
          </CardBody>
        </Card>

        <Card
          id='contact'
          w='full'
          bg='#efd4cd'
        >
          <CardHeader>
            <Heading
              size='md'
              color='#604461'
            >
              お問合せ
            </Heading>
          </CardHeader>
          <CardBody
          >
            <Text fontSize='4xl'>080-3472-6293</Text>
          </CardBody>
        </Card>

        {/* Footer */}
        <SimpleGrid
          bg='#b9cdbf'
          w='full'
          columns={{ base: 2, md: 3 }}
        >
          <Center py={5}>
            <Link href='#top' color='#604461'>トップ</Link>
          </Center>
          <Center py={5}>
            <Link href='#photo' color='#604461'>店内写真</Link>
          </Center>
          <Center py={5}>
            <Link href='#info' color='#604461'>店舗情報</Link>
          </Center>
          <Center py={5}>
            <Link href='#menu' color='#604461'>料金・メニュー</Link>
          </Center>
          <Center py={5}>
            <Link href='#access' color='#604461'>アクセスMAP</Link>
          </Center>
          <Center py={5}>
            <Link href='#contact' color='#604461'>お問合せ</Link>
          </Center>
        </SimpleGrid>
        {/* Footer */}

        <Text
          mb={5}
        >
          @2024 楽楽リラクゼーション <Link href='https://raku-raku.net' color='teal.800'>raku-raku.net</Link>
        </Text>
      </VStack>
      {/* Main */}
    </Container>
  );
};
